import styled from 'styled-components'

import { Button, Tag } from 'common/UI'
import { getValidIcon } from 'common/UI/Icon'
import { ClinicSectionHeaderStoryblok } from 'common/types'
import { getAnchorFromCmsLink, textByLine } from 'common/utils/content'
import { cn } from 'common/utils/tailwind'

type Props = {
  block: ClinicSectionHeaderStoryblok
}

export const ClinicSectionHeader = ({
  block,
  ...props
}: Props): JSX.Element => {
  const { variant, tag, title, description, call_to_action } = block

  return (
    <div className="w-full overflow-hidden" {...props}>
      <div className="mx-auto max-w-screen-desktop">
        <div
          className={cn(
            'flex flex-col px-5 py-10 lg:px-20',
            variant === 'center' && 'items-center',
            variant === 'center' ? 'text-center' : 'text-start',
            'lg:pt-30 lg:pb-15',
            variant === 'default' && 'lg:py-15',
            variant === 'center' && 'lg:pt-20 lg:pb-10'
          )}
        >
          {tag && <StyledTag variant={variant} label={tag} />}

          <div
            className={cn(
              'flex flex-col w-full justify-between',
              variant === 'center' && 'items-center',
              variant === 'center'
                ? 'flex-col gap-4 lg:gap-5'
                : 'gap-4 md:flex-row'
            )}
          >
            {title && (
              <div
                className={cn(
                  variant === 'center' ? 'max-w-[630px]' : 'max-w-[530px]'
                )}
              >
                <h3
                  className={cn(
                    'font-regular text-title-medium',
                    variant === 'center'
                      ? 'md:text-title-large'
                      : 'md:text-title'
                  )}
                >
                  {title}
                </h3>
              </div>
            )}

            {description && (
              <div className="lg:max-w-[630px] space-y-3">
                {textByLine(description, (part) => (
                  <>
                    <p className="text-eighteen md:text-twenty">{part}</p>
                  </>
                ))}
              </div>
            )}

            {call_to_action?.[0] &&
              (() => {
                const { icon, label, alternative_text, link } =
                  call_to_action[0]

                const typedIcon = getValidIcon(icon)

                const { href, target, rel } = getAnchorFromCmsLink(link)

                return (
                  <Button
                    as="a"
                    rel={rel}
                    href={href}
                    target={target}
                    icon={typedIcon}
                    variant="outline"
                    iconPosition="right"
                    alternativeText={alternative_text}
                    className={cn(variant !== 'center' && 'hidden')}
                  >
                    {label}
                  </Button>
                )
              })()}
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledTag = styled(Tag)<{
  variant: ClinicSectionHeaderStoryblok['variant']
}>`
  margin-bottom: 1rem;

  ${({ theme }) => theme.media.lg} {
    margin-bottom: ${({ variant }) =>
      variant === 'center' ? '1rem' : '1.25rem'};
  }
`
